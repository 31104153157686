<template>
  <v-card v-if="profile" class="box-shadow" outlined>
    <v-card-text class="pa-7 secondary--text">
      <div class="font-weight-bold">Members</div>
      <v-divider class="my-3"></v-divider>

      <div v-if="profile.members && profile.members.length">
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(member, i) of profile.members" :key="i">
                <td>{{ $store.getters['users/user'](member).fullName }}</td>
                <td>
                  <a 
                    :href="`mailto:${$store.getters['users/user'](member).email}`"
                    class="text-decoration-none"
                  >
                    {{ $store.getters['users/user'](member).email }}
                  </a>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div v-else>No member found</div>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    profile: Object,
  },

  watch: {
    'profile': {
      handler(profile) {
        if (profile.members && profile.members.length) {
          profile.members.forEach(member => {
            this.$store.dispatch('users/getUser', member)
          })
        }
      },
      immediate: true
    }
  }
}

</script>