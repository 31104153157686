<template>
  <div >
    <admin-title
      :title="profile.name"
      back="BrandProfiles"
      v-if="!noHeader"
      editable
      @updated="titleUpdated"
    ></admin-title>

    <div>
      <brand-information :profile="profile" class="mb-5" :readonly="readonly" />
      <brand-colors :profile="profile" :description="colorDescription._value" class="mb-5" :readonly="readonly" />
      <brand-logo :profile="profile" :description="logoDescription._value" class="mb-5" :readonly="readonly" />
      <brand-guide :profile="profile" :description="brandGuideDescription._value" class="mb-5" :readonly="readonly" />
      <brand-fonts :profile="profile" :description="fontDescription._value" class="mb-5" :readonly="readonly" />
      <brand-photos-videos :profile="profile" :description="photographsDescription._value" class="mb-5" :readonly="readonly" />
      <brand-assets-links :profile="profile" :description="assetsDescription._value" class="mb-5" :readonly="readonly" />
      <social-media-links :profile="profile" :description="socialMediaDescription._value" class="mb-5" :readonly="readonly" />
      <login-details :profile="profile" :description="loginDescription._value" class="mb-5" :readonly="readonly" />
      <Members v-if="user && (user.organization || user.role === 'admin')" :profile="profile" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase'
import { mapState, mapActions } from 'vuex'

import Members from './components/Members'
import BrandLogo from './components/BrandLogo'
import BrandGuide from './components/BrandGuide'
import BrandFonts from './components/BrandFonts'
import BrandColors from './components/BrandColors'
import LoginDetails from './components/LoginDetails'
import BrandInformation from './components/BrandInformation'
import BrandAssetsLinks from './components/BrandAssetsLinks'
import SocialMediaLinks from './components/SocialMediaLinks'
import BrandPhotosVideos from './components/BrandPhotosVideos'

export default {
  metaInfo() {
    return {
      title: this.profile.name
    }
  },

  props: {
    id: String,
    readonly: Boolean,
    noHeader: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      logoDescription: {},
      fontDescription: {},
      colorDescription: {},
      loginDescription: {},
      assetsDescription: {},
      brandGuideDescription: {},
      photographsDescription: {},
      socialMediaDescription: {},
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.brand_profile.status,
    }),

    profile: function () {
      return this.$store.getters['brand_profile/profile'](this.id || this.$route.params.id)
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'id': {
      handler(value) {
        if (value) this.getProfile(value)
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Members,
    BrandLogo,
    BrandGuide,
    BrandFonts,
    BrandColors,
    LoginDetails,
    BrandInformation,
    BrandAssetsLinks,
    SocialMediaLinks,
    BrandPhotosVideos,
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *-----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'getProfile'
    ]),

    getConfigs() {
      const remoteConfig = firebase.remoteConfig()
      remoteConfig.settings.minimumFetchIntervalMillis = 1500

      remoteConfig.fetchAndActivate()
      .then(() => {
        this.colorDescription = remoteConfig.getValue('colour_description')
        this.logoDescription = remoteConfig.getValue('logo_description')
        this.brandGuideDescription = remoteConfig.getValue('brand_guide_description')
        this.fontDescription = remoteConfig.getValue('font_description')
        this.photographsDescription = remoteConfig.getValue('photographs_description')
        this.assetsDescription = remoteConfig.getValue('assets_description')
        this.socialMediaDescription = remoteConfig.getValue('social_media_description')
        this.loginDescription = remoteConfig.getValue('login_description')
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    titleUpdated(value) {
      this.$store.dispatch('brand_profile/updateBrandFields', {
        profile: this.profile,
        fields: { name: value },
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getProfile(this.$route.params.id)
    this.getConfigs()
  }
}
</script>

<style>

.form-label {
  margin-bottom: 5px;
  font-size: 0.7rem;
  display: block;
  font-weight: 600;
  font-family: 'Poppins';
}

</style>