<template>
  <v-card class="box-shadow" outlined>
    <v-card-text class="pa-7 secondary--text">
      <div class="font-weight-bold">Login Details</div>
      <v-divider class="my-3"></v-divider>

      <v-sheet max-width="650">
        <p v-if="description" v-html="$options.filters.linkify(description)" class="text-pre-wrap"></p>

        <preloader v-if="getting" />

        <v-simple-table v-if="!getting && logins.length">
          <template #default>
            <thead>
              <tr>
                <th>PLATFORM</th>
                <th>LOGIN</th>
                <th>PASSWORD</th>
                <th></th>
              </tr>
            </thead>
            <v-fade-transition tag="tbody" group>
              <tr v-for="login in logins" :key="login.id">
                <td>{{ login.platform }}</td>
                <td>{{ login.login }}</td>
                <td>*************</td>
                <td width="15" class="text-right">
                  <v-menu v-if="$store.getters['brand_profile/haveAccess'](profile)" offset-y>
                    <template #activator="{ on }">
                      <v-btn 
                        color="primary" 
                        v-on="on"
                        icon
                        dark
                      >
                        <v-icon>mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="copyText(login.login)">
                        <v-list-item-title>Copy login</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="copyPassword(login.id)">
                        <v-list-item-title>Copy Password</v-list-item-title>
                      </v-list-item>
                      <template v-if="!readonly">
                        <v-list-item @click="setData(login)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-list-item @click="confirmDelete(login)">
                          <v-list-item-title class="error--text">Delete</v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </v-fade-transition>
          </template>
        </v-simple-table>

        <v-card v-if="!readonly" class="box-shadow mt-5" outlined>
          <v-card-text class="pa-5">
            <v-form ref="form">
              <v-row dense>
                <v-col>
                  <label class="form-label">Platform Name:</label>
                  <v-text-field
                    v-model="data.platform"
                    placeholder="Enter the platform name"
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <label class="form-label">Login:</label>
                  <v-text-field
                    v-model="data.login"
                    placeholder="Enter your related login"
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col>
                  <label class="form-label">Password:</label>
                  <v-text-field
                    v-model="data.password"
                    @click:append="showPassword = !showPassword"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="Enter your related password"
                    :rules="[rules.required]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>

            <v-btn 
              @click="validateForm()"
              :loading="status.saving"
              color="primary"
              class="mr-2"
              small
            >{{ data.id ? 'Update' : 'Save' }} Details</v-btn>

            <v-btn 
              v-if="data.id"
              @click="setData({
                platform: null,
                password: null,
                login: null,
              })"
              text
            >Cancel</v-btn>
          </v-card-text>
        </v-card>
      </v-sheet>
    </v-card-text>

    <!-- DELETE DIALOG -->
    <confirm-delete
      :show="deleteDialog"
      :message="deleteMessage"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
    />
  </v-card>
</template>

<script>
import rules from '@/rules'
import db from '@/firebase/init'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    profile: Object,
    readonly: Boolean,
    description: String,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      logins: [],
      toDelete: null,
      getting: false,
      deleteDialog: false,
      showPassword: false,
      deleteMessage: null,
      data: {
        platform: null,
        password: null,
        login: null,
      },
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.brand_profile.status
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'profile': {
      handler(value) {
        if (Object.keys(value).length)
          this.getLogins()
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('brand_profile', [
      'saveLogin',
      'deleteLogin'
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        Promise.resolve(this.saveLogin({
          profile: this.profile,
          data: this.data
        }))
        .then((response) => {
          let exists = this.logins.find(l => l.id == response.id)
          
          if (!exists)
            this.logins.push(response)
          else 
            this.$set(this.logins, this.logins.indexOf(exists), response)
          
          this.data = {
            platform: null,
            password: null,
            login: null,
          }

          this.$refs.form.resetValidation()
        })
      }
    },

    getLogins() {
      this.logins = []
      this.getting = true

      this.profile.ref
      .collection('logins')
      .get().then((snapshot) => {
        if (snapshot.size) {
          snapshot.forEach(doc => {
            let login = doc.data()
            login.ref = doc.ref
            login.id = doc.id
            let exists = this.logins.find(l => l.id == login.id)
            
            if (!exists) 
              this.logins.push(login)

          })
        }
        
        this.getting = false
      })
      .catch(error => {
        this.getting = false
        console.log(error.message)
      })
    },

    copyText(text) {
      this.$copyText(text)
      .then(() => {
        this.copiedToClipboard()
      }, 
      (e) => {
        console.log(e)
        this.$store.dispatch('showError', e)
      })
    },

    copiedToClipboard() {
      this.$store.dispatch('showSuccess', 'Copied to clipboard')
    },

    copyPassword(id) {
      db.collection('passwords')
      .doc(id).get()
      .then(doc => {
        if (doc.exists) {
          let password = doc.data().password
          this.copyText(password)
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    confirmDelete(login) {
      this.toDelete = login
      this.deleteMessage = `Delete ${login.platform} login details?`
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.resolve(this.deleteLogin(this.toDelete))
      .then(() => {
        let login = this.logins.find(l => l.id == this.toDelete.id)
        this.logins.splice(this.logins.indexOf(login), 1)
        this.closeDeleteDialog()
      })
    },

    setData(login) {
      this.data = login
      this.$refs.form.resetValidation()
    }
  }

}
</script>